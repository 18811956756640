import { Categorie, CategorieClassement, Session, FormationAbstract } from "../formations/type"

export interface FormationHeadGroup {
  totalCount: number
  fieldValue: Categorie
  nodes: FormationAbstract[]
}

export interface FormationShop {
  titre: string
  reference: string
  url: string
  shop: string | null
  ffta: boolean | null
  sessions: Session[]
}

export type ShopLevel = "none" | "formation" | "session" | "date"

export interface ShopAction {
  id: string
  formationId: string
  mode: ShopLevel
}

export interface ShopActions {
  formationId: string
  mode: ShopLevel
  actions: ShopAction[]
}

function notEmpty(str: string | null | undefined): boolean {
  return str != null && str.length > 0
}

function hasSessionShop(sessions: Session[]): boolean {
  return sessions.findIndex(session => notEmpty(session.shop)) !== -1
}

function hasDateShop(sessions: Session[]): boolean {
  return sessions.findIndex(session => session.dates != null && session.dates.findIndex(date => notEmpty(date.shop)) !== -1) !== -1
}

export function shopActionsFromFormation(formation: FormationShop): ShopActions {
  const formationId = formation.url
  let mode: ShopLevel = "none"
  const ids: string[] = []

  if (formation.shop != null && formation.shop.length > 0) {
    ids.push(formation.shop)
  } else if (formation.sessions != null && hasSessionShop(formation.sessions)) {
    mode = "session"
    formation.sessions.forEach(session => {
      if (session.shop != null && session.shop.length > 0) {
        ids.push(session.shop)
      }
    })
  } else if (formation.sessions != null && hasDateShop(formation.sessions)) {
    mode = "date"
    formation.sessions.forEach(session => {
      if (session.dates == null) {
        return
      }

      session.dates.forEach(date => {
        if (date.shop != null && date.shop.length > 0) {
          ids.push(date.shop)
        }
      })
    })
  }

  return {
    formationId,
    mode,
    actions: ids.map(id => ({
      id,
      formationId,
      mode,
    })),
  }
}

export function categorieToStrPl(categorie: Categorie): string {
  switch (categorie) {
    case "CS":
      return "Stages sportifs"
    case "CF":
      return "Formations initiales et continues"
    case "CA":
      return "Formations arbitres"
    default:
      return categorie
  }
}

export function categorieClassementToStr(categorie: CategorieClassement): string {
  switch (categorie) {
    case "P":
      return "Poussin"
    case "M":
      return "Minime"
    case "B":
      return "Benjamin"
    case "C":
      return "Cadet"
    case "J":
      return "Junior"
    case "S1":
      return "Senior 1"
    case "S2":
      return "Senior 2"
    case "S3":
      return "Senior 3"
    default:
      return categorie
  }
}
